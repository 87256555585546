import React, {FunctionComponent} from 'react';
import {FormattedMessage} from 'react-intl';

import './styles.scss';

export const Term: FunctionComponent = () => {
  return <div className="term">
    <header className="term__title-window">
      {'Gabi: Therapist assisted chatbot for increasing therapeutic'}<br/>
      {'outcomes in people with problem gambling'}
    </header>
    <header className="term__title-window">
      {'Website Terms & Conditions'}
    </header>
    <h4 className="term__title">
      {'1. Use of Gabi'}
    </h4>
    <ul>
      <li className="term__description">
        {'By accessing or using this website or mobile application ('}<strong>{'“Gabi”'}</strong>{'), you confirm that you have read, '
          + 'understand, accept and agree to these terms and conditions ('}<strong>{'“Terms”'}</strong>{'). These Terms form a '
          + 'binding agreement between you and us for the provision of access to and your use of Gabi. If you '
          + 'disagree with any of the Terms you must stop using Gabi and navigate away from it. If you have '
          + 'any questions regarding these Terms, please contact us directly.'}
      </li>
      <li className="term__description">
        {'We may amend these Terms from time to time and you are deemed to accept the changes if you '
          + 'continue to use Gabi after the changes have been made. If you do not agree with any changes you '
          + 'must stop using Gabi. It is your responsibility to check the Terms for any changes when you use '
          + 'Gabi.'}
      </li>
      <li className="term__description">
        {'We reserve the right to refuse you access to Gabi at any time for any reason whatsoever, in our '
          + 'absolute discretion.'}
      </li>
      <li className="term__description">
        {'You acknowledge that Gabi is intended to be used solely by participants in a research study '
          + 'conducted by Flinders University and Southern Adelaide Local Health Network ('}<strong>{'“SALHN”'}</strong>{') (Project Number: 196.23) '
          + '('}<strong>{'“Research Study”'}</strong>{') for the specific purpose of carrying the Research '
          + 'Study. If you are not a participant in the Research Study, you must not use Gabi.'}
      </li>
      <li className="term__description">
        {'To use Gabi, you must also agree to the terms outlined in the Participant Information and Consent '
          + 'Form ('}<strong>{'“Consent Form”'}</strong>{') which has been provided to you. This form provides important '
          + 'information about the study, including its purpose, procedures, risks, and benefits. By agreeing to '
          + 'the Consent Form, you consent to participate in the study and to the use of your information as '
          + 'described in the Consent Form.'}
      </li>
      <li className="term__description">
        {'Gabi may not be used by individuals under the age of 18. If you are under the age of 18, you are '
          + 'not permitted to access Gabi.'}
      </li>
    </ul>
    <h4 className="term__title">
      {'2. Content and Intellectual Property'}
    </h4>
    <ul>
      <li className="term__description">
        {'Gabi and all of its content is the intellectual property of Flinders University and our research '
          + 'partners. By allowing you access to Gabi, we do not grant you any right or licence to use that '
          + 'content for any purpose other than for your participation in the Research Study.'}
      </li>
    </ul>
    <h4 className="term__title">
      {'3. Account Details, Personal Information, and System Security'}
    </h4>
    <ul>
      <li className="term__description">
        {'When using Gabi you must not provide any other persons’ personal information, email address or '
          + 'details.'}
      </li>
      <li className="term__description">
        {'Where you are provided with a password, you agree to keep that password confidential and not '
          + 'allow others to use it.'}
      </li>
      <li className="term__description">
        {'You agree that you will not, and will not allow any other person to:'}
        <ul>
          <li className="term__description">
            {'use Gabi for purposes other than those permitted by us;'}
          </li>
          <li className="term__description">
            {'use Gabi for any illegal, unlawful or improper purpose;'}
          </li>
          <li className="term__description">
            {'use any software which may threaten the integrity and security of Gabi or any data;'}
          </li>
          <li className="term__description">
            {'upload, transmit or share any virus or similar computer code or software that may cause '
              + 'damage to or violate the privacy of our data or the data of other users;'}
          </li>
          <li className="term__description">
            {'attempt to copy, reverse engineer, or otherwise take any action for the purposes of replicating, '
              + 'duplicating, or deriving works from the content or code that makes up Gabi.'}
          </li>
        </ul>
      </li>
      <li className="term__description">
        {'You agree to use Gabi in accordance with all applicable laws and regulations relating to the use of '
          + 'the internet South Australia and in the State or Territory in which you live.'}
      </li>
    </ul>
    <h4 className="term__title">
      {'4. Medical Disclaimer'}
    </h4>
    <ul>
      <li className="term__description">
        {'The information provided in Gabi, including options for medical treatment, is provided for '
          + 'informational purposes only and is not intended to replace professional medical advice, diagnosis '
          + 'or treatment. Always seek the advice of your doctor or other qualified health provider for any '
          + 'questions you may have regarding a medical condition. Do not disregard or delay seeking '
          + 'professional medical advice because of something you have read on Gabi.'}
      </li>
    </ul>
    <h4 className="term__title">
      {'5. Disclaimers'}
    </h4>
    <ul>
      <li className="term__description">
        {'You acknowledge that Gabi is provided as part of an ongoing medical research trial and is currently '
          + 'in its testing phase. The content, features, and services offered via Gabi are subject to change as '
          + 'we continue to refine and improve our platform. You acknowledge and agree that the information '
          + 'provided, and Gabi’s functionality, may contain errors or inaccuracies.'}
      </li>
      <li className="term__description">
        {'To the greatest extent permissible by law, we give no warranty and make no representation, express '
          + 'or implied, as to the accuracy, adequacy or appropriateness of the information provided on Gabi '
          + 'for your particular needs or purposes.'}
      </li>
      <li className="term__description">
        {'We are not liable in any circumstances for special, indirect or consequential loss or any damages '
          + 'whatsoever, including those resulting from loss of use, loss of data or loss of revenues or profits, '
          + 'whether in an action of contract, negligence or otherwise, arising out of or in connection with your '
          + 'use of Gabi.'}
      </li>
      <li className="term__description">
        {'We do not make any guarantee regarding your ability to access Gabi, which may from time to time '
          + 'be offline.'}
      </li>
    </ul>
    <h4 className="term__title">
      {'6. Release'}
    </h4>
    <ul>
      <li className="term__description">
        {'Should you choose to use Gabi, you do so at your own risk and agree that Flinders University and '
          + 'SALHN are not responsible for any loss or damage arising out of your use of Gabi. To the greatest '
          + 'extent permissible by law, you agree to release Flinders University and SALHN from any loss, '
          + 'cost, claim or demand arising in any way out of your use of Gabi.'}
      </li>
    </ul>
    <h4 className="term__title">
      {'7. Other'}
    </h4>
    <ul>
      <li className="term__description">
        {'If any of these terms are at any time held by any jurisdiction to be void, invalid or unenforceable, '
          + 'then they shall be treated as changed or read down to the extent necessary to bring them within the '
          + 'laws of that jurisdiction and to prevent them from being void, and the remainder of the agreement '
          + 'shall be binding in that changed or reduced form. Subject to that, each provision or part thereof '
          + 'shall be interpreted as severable and shall not in any way affect any other of these terms.'}
      </li>
      <li className="term__description">
        {'No waiver by us, in exercising any right, power or provision in this agreement shall operate as a '
          + 'waiver of any other right or of that same right at a future time; nor shall any delay in exercise of '
          + 'any power or right be interpreted as a waiver.'}
      </li>
      <li className="term__description">
        {'This agreement and any transactions governed by it will be governed by and construed in '
          + 'accordance with the law of South Australia. You submit to the exclusive jurisdiction of courts in '
          + 'South Australia.'}
      </li>
    </ul>
    <header className="privacy__title-window">
      {'Privacy and Collection Statement'}
    </header>

    <p className="privacy__description">
      {'The Flinders University ('}<strong>{'“we”'}</strong>{' or '}<strong>{'“us”'}</strong>{') Privacy Policy '
        + '(available '}
      <a href={'https://www.flinders.edu.au/content/dam/documents/staff/policies/facilities-info-management/privacy-policy.pdf'}
        className={'privacy__list__item__description'} target={'_blank'} rel="noreferrer">
        {'here'}
      </a>
      {') ('}<strong>{'“Policy”'}</strong>{') applies to how we collect, hold, use and protect Personal Information that we obtain from you when you use Gabi.'}
    </p>

    <p className="privacy__description">
      {'We encourage you to read the Policy. If you do not agree with any part of the Policy, '
        + 'we recommend that you do not provide your Personal Information to us.'}
    </p>

    <p className="privacy__description">
      {'You acknowledge that Gabi is intended to be used solely by participants in a research study conducted by '
        + 'Flinders University and Southern Adelaide Local Health Network ('}<strong>{'“SALHN”'}</strong>{') '
        + '(Project Number: 196.23) ('}<strong>{'“Research Study”'}</strong>{') for the specific purpose of carrying '
        + 'out activities in accordance with the Research Study. If you are not a participant in the '
        + 'Research Study, you are not permitted to use or access Gabi.'}
    </p>

    <p className="privacy__description">
      {'By using Gabi you agree to be bound by the terms of this Privacy and Collection Statement.'}
    </p>

    <p className="privacy__description">
      {'Gabi may not be used by individuals under the age of 18. If you are under the age of 18, you are not permitted to access Gabi.'}
    </p>

    <h4 className="privacy__title">{'Personal Information'}</h4>
    <p className="privacy__description">
      {'Personal Information means information or an opinion about an identified individual, or an individual who is reasonably '
        + 'identifiable, whether the information or opinion is true or not and whether the information or opinion is recorded in a material form or not.'}
    </p>

    <h4 className="privacy__title">{'Sensitive Information'}</h4>
    <p className="privacy__description">
      {'Australia’s privacy laws consider the below information to be Sensitive Information:'}
    </p>
    <ul className="privacy__description">
      <li className="privacy__description">{'information or an opinion about an individual’s racial or ethnic origin, political opinions, '
        + 'membership of a political association, religious beliefs or affiliations, philosophical beliefs, '
        + 'membership of a professional or trade association or trade union, sexual orientation or practices, or criminal record;'}</li>
      <li className="privacy__description">{'health information about an individual;'}</li>
      <li className="privacy__description">{'genetic information about an individual that is not otherwise health information;'}</li>
      <li className="privacy__description">
        {'biometric information that is to be used for the purpose of automated biometric verification or biometric identification; or'}
      </li>
      <li className="privacy__description">{'biometric templates.'}</li>
    </ul>

    <p className="privacy__description">
      {'While we will only ask you to provide Sensitive Information that is relevant to your participation in the '
        + 'Research Study (for example, information about your health), you may voluntarily choose to disclose additional '
        + 'Sensitive Information to Gabi if you wish.'}
    </p>

    <h4 className="privacy__title">{'Personal and Sensitive Information Collected'}</h4>
    <p className="privacy__description">
      {'In the course of your use of Gabi we may collect and hold data and information that directly or indirectly '
        + 'identifies you, which may include identity data (e.g. your name, address, email address, username and password, '
        + 'phone number), your IP address, mobile device ID, time zone setting, language preference, operating system, '
        + 'your geographic location, the dates and times of your use and sharing of Gabi, any other data that you enter '
        + 'into Gabi, and the other kinds of information described in the Policy.'}
    </p>

    <p className="privacy__description">
      {'Throughout the course of using Gabi, you may be requested to provide (or you may make an unsolicited disclosure of) '
        + 'other Personal or Sensitive Information (including health information). '
        + 'Any information which you choose to provide to or share with Gabi will be collected and held by us '
        + 'in accordance with this Privacy and Collection Statement, the Participant Information and Consent Form, and the Policy.'}
    </p>

    <p className="privacy__description">
      {'Without your Personal Information, Flinders University will not be able to conduct the Research Study and you '
        + 'will not be able to fulfill the participation requirements as set out in the Participant Information and Consent '
        + 'Form that participants in the Research Study signed prior to, and as a condition of participating in the Research Study. '
        + 'The Personal and Sensitive Information that you provide is required for use in conducting and analysing the results of the Research Study.'}
    </p>

    <p className="privacy__description">
      {'In addition to the data and information that we may collect from your use of Gabi, '
        + 'the other parties involved in the development, delivery and assessment of Gabi and the Research Study '
        + '(including the parties outlined in the Participant Information and Consent Form) ('}<strong>{'“Study Partners”'}</strong>{') may also '
        + 'collect certain information and interaction data from you, including usage data through the use of cookies '
        + '(being small text files stored on your device when you visit Gabi, essential for ensuring functionality, security, '
        + 'and a better user experience) and IP address and browser information. We may also disclose the Personal Information '
        + 'that we collect from you to our Study Partners and service providers for the purposes of developing, modifying or '
        + 'improving Gabi and to analyse the results of the Research Study. Our Study Partners and service providers are '
        + 'located in Australia and the United States of America, and further information regarding Personal Information '
        + 'collected by these parties is available in their own privacy policies, available upon request.'}
    </p>

    <h4 className="privacy__title">{'Consent to Use Personal Information'}</h4>
    <p className="privacy__description">
      {'You consent to our collection of your Personal Information and to us collecting, using and disclosing that '
        + 'Personal Information in the manner and for the purposes described in the Policy and the Participant Information and Consent Form.'}
    </p>

    <h4 className="privacy__title">{'Privacy Policy Summary'}</h4>
    <p className="privacy__description">
      {'Under the Policy we comply with the Australian Privacy Principles as set out in the '
        + 'Privacy Act 1988 (Cth) and with Regulation EU (2016/679) General Data Protection Regulation.'}
    </p>

    <p className="privacy__description">
      {'The Policy details:'}
    </p>
    <ul className="privacy__description">
      <li className="privacy__description">{'the kinds of Personal Information we may collect;'}</li>
      <li className="privacy__description">{'the ways we may directly and indirectly collect Personal Information;'}</li>
      <li className="privacy__description">{'the ways we hold, store and secure Personal Information; and'}</li>
      <li className="privacy__description">{'the purposes for which we collect hold, use and disclose Personal Information.'}</li>
    </ul>

    <p className="privacy__description">
      {'In addition to the Policy, your Personal Information will be collected, held, used and disclosed in accordance '
        + 'with this Privacy and Collection Statement and the Participant Information and Consent Form.'}
    </p>

    <p className="privacy__description">
      {'We will use your Personal Information only for the purposes for which we collect it. '
        + 'We (or our Study Partners) may also use it to provide, improve and protect the App.'}
    </p>

    <p className="privacy__description">
      {'Your Personal Information will also be protected, anonymised, retained and deleted in accordance with the Participant Information and Consent Form.'}
    </p>

    <h4 className="privacy__title">{'Disclosure of Personal Information'}</h4>
    <p className="privacy__description">
      {'We understand how important it is to keep your Personal Information confidential and private and will only '
        + 'disclose your Personal Information to third parties or other Flinders staff in the following circumstances:'}
    </p>
    <ul className="privacy__description">
      <li className="privacy__description">{'where we are required to disclose the information by law;'}</li>
      <li className="privacy__description">
        {'where disclosure is warranted in order to prevent an imminent threat to your health or safety, '
        + 'or the health or safety of another person in accordance with law;'}
      </li>
      <li className="privacy__description">
        {'as otherwise set out in the Policy, this Privacy and Collection Statement or the Participant Information and Consent Form.'}
      </li>
    </ul>

    <h4 className="privacy__title">{'European Union'}</h4>
    <p className="privacy__description">
      {'If you are an individual located in the European Union, the additional privacy provisions set out in the Policy will apply to you.'}
    </p>

    <h4 className="privacy__title">{'Overseas disclosure'}</h4>
    <p className="privacy__description">
      {'In some instances, your Personal Information may be transferred overseas in circumstances where our '
        + 'Study Partners and service providers (such as the third-party developer and host of Gabi) have servers and '
        + 'service providers located overseas (such as the United Kingdom or the United States of America). '
        + 'We have agreements with our Study Partners to ensure that they take all necessary measures to ensure that your '
        + 'data is treated securely and in accordance with this Privacy and Collection Statement.'}
    </p>

    <h4 className="privacy__title">{'Accessing or correcting Personal Information'}</h4>
    <p className="privacy__description">
      {'You may request access to and seek correction of your Personal Information in accordance with the Policy.'}
    </p>

    <h4 className="privacy__title">{'Questions or complaints'}</h4>
    <p className="privacy__description">
      {'You can raise questions or complaints about how we have handled your Personal Information by contacting '
        + 'Flinders University in the manner set out in the Policy. Flinders University will acknowledge receipt of your '
        + 'complaint in writing, within five business days, and investigate and respond to you within 30 business days.'}
    </p>
  </div>;
};
