import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';

import {IStore} from 'redux/interface';
import {IArticle} from 'redux/content/interfaces';
import {addFavoriteCardAction, addMaterialAction, removeFavoriteCardAction, removeMaterialAction} from 'redux/content/contentAsyncActions';

import {ButtonVariant, GritxButton, IVoice, TextToSpeech} from '@wholesalechange/chatcomponent';
import Meditation from 'components/meditation';
import Journal from 'components/journal';
import SelfInterview from 'components/self-interview';

import {ContentType, proUser} from 'utils/constants/constants';

import {ReactComponent as FullHeart} from 'assets/image/library/full-heart.svg';
import {ReactComponent as EmptyHeart} from 'assets/image/library/empty-heart.svg';
import Clock from 'assets/image/library/clock.svg';

import './styles.scss';

interface IContentLayout {
  card: IArticle
  onClose?: () => void
  hideContentHeader?: boolean
}

export const Article: React.FunctionComponent<IContentLayout> = ({
  card,
  onClose,
  hideContentHeader
}: IContentLayout) => {
  const {
    auth: {
      auth0User,
      userProfile
    },
    translation: {
      locale
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const intl = useIntl();
  const proFlag = auth0User?.['https://gritx.org/roles'].includes(proUser);
  const [isAddedToMaterials, setIsAddedToMaterials] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isShowJournal, setIsShowJournal] = useState(false);
  const [isShowInterview, setIsShowInterview] = useState(false);
  const [journalPage, setJournalPage] = useState<null | number>(null);
  const [isShowJournalArticle, setIsShowJournalArticle] = useState(false);
  const [voice, setVoice] = useState<IVoice>();
  const [text, setText] = useState<string>();
  const hideMaterials = process.env.REACT_APP_HIDE_MATERIALS === 'true';

  function stripHtml(html: string) : string {
    const tmp = document.createElement('DIV');

    tmp.innerHTML = html;

    return tmp.textContent || tmp.innerText || '';
  }

  function getLocale(lang: string) {
    const matches = lang.match(/(\w+)-?/);

    if (matches) {
      return matches[1];
    }

    return lang;
  }

  useEffect(() => {
    setIsAddedToMaterials(card.material);
    setIsFavorite(card.favorite);
    if (card.settings?.voices.length && card.text) {
      setText(stripHtml(card.text));

      const localVoice = card.settings.voices.filter(v => getLocale(v.lang) === locale)[0];

      if (localVoice) {
        setVoice(localVoice);
      } else {
        const defaultVoice = card.settings.voices.filter(v => v.default)[0];

        setVoice(defaultVoice);
      }
    }
  }, [card]);

  useEffect(() => {
    setIsShowJournal(false);
  }, [card.id]);

  function addToMaterials() {
    setIsAddedToMaterials(!isAddedToMaterials);
    if (isAddedToMaterials) {
      dispatch(removeMaterialAction([card.id]));
    } else {
      dispatch(addMaterialAction(card.id));
    }
  }

  function changeFavorite() {
    setIsFavorite(!isFavorite);
    if (isFavorite) {
      dispatch(removeFavoriteCardAction(card.id));
    } else {
      dispatch(addFavoriteCardAction(card.id));
    }
  }

  function handleClose() {
    if (onClose) {
      if (isShowJournal) {
        if (confirm(intl.formatMessage({
          id: 'gritx.common.notification.close-tab',
          defaultMessage: 'Changes you made may not be saved.'
        }))) {
          onClose();
        }

        return;
      }
      onClose();
    }
  }

  function handleShowJournal(val: boolean) {
    return () => setIsShowJournal(val);
  }

  function handleChangeJournalPage(page: number | null) {
    if (typeof page === 'number') {
      setJournalPage(page + 1);
    } else {
      setJournalPage(page);
    }
  }

  function toggleShowJournalArticle(val: boolean) {
    setIsShowJournalArticle(val);
  }

  function handleStartInterview() {
    setIsShowInterview(true);
  }

  function handleFinishInterview() {
    setIsShowInterview(false);
  }

  const renderAddToMaterialsButton = () => (proFlag && !hideMaterials
    && <GritxButton
      title={isAddedToMaterials
        ? intl.formatMessage({
          id: 'gritx.library.card.saved',
          defaultMessage: 'Saved'
        })
        : intl.formatMessage({
          id: 'gritx.library.card.addToMaterials',
          defaultMessage: 'Add to Materials'
        })}
      variant={ButtonVariant.Primary}
      onClick={addToMaterials}
    />);

  const renderActionsSection = () => {
    if (!userProfile || userProfile?.greetingContentId === card.id) {
      return null;
    }

    return <>
      {renderAddToMaterialsButton()}
      <button className="article__button-icon" onClick={changeFavorite}>
        <div className="article__icon">
          {
            isFavorite
              ? <FullHeart className="article__icon-img"/>
              : <EmptyHeart className="article__icon-img"/>
          }
        </div>
      </button>
    </>;
  };

  const renderMeditation = () => (card.type === ContentType.Meditation
    && <Meditation card={card}/>);

  const renderJournal = () => (card.type === ContentType.Journal
    && <Journal
      card={card}
      onStart={handleShowJournal(true)}
      onChangePage={handleChangeJournalPage}
      onStop={handleShowJournal(false)}
      onShowJournalArticle={toggleShowJournalArticle}
    />);

  const renderSelfInterview = () => (card.type === ContentType.SelfInterview
    && <SelfInterview
      card={card}
      onStart={handleStartInterview}
      onFinish={handleFinishInterview}
    />);

  const renderArticleInformation = () => (isShowJournal
    ? journalPage && !isShowJournalArticle
    && <p className={'article__page-number'}>
      <FormattedMessage
        id={'gritx.library.page'}
        defaultMessage={'Page {page}'}
        values={{page: journalPage}}
      />
    </p>
    : <>
      <div className="article__icon">
        {card.labelPictureUrl && <img
          className="article__icon-img"
          src={card.labelPictureUrl}
          alt={card.labelText}
          title={card.labelText}
        />}
      </div>
      {/* {card.readingImageUrl && <div*/}
      {/*   className="article__icon article__icon--reading-time"*/}
      {/*   style={{backgroundImage: `url('${card.readingImageUrl || Clock}')`}}*/}
      {/*   title={card.readingName}*/}
      {/* />}*/}
    </>);

  const renderTextToSpeech = () => {
    if (voice && text) {
      return <TextToSpeech styleClass="chat__message-text-to-speech" textMessages={[text]} voice={voice}
        ttsSettings={{
          azureSubscriptionKey: process.env.REACT_APP_AZURE_SUBSCRIPTION_KEY || '',
          azureRegion: process.env.REACT_APP_AZURE_REGION || ''
        }}/>;
    }

    return <></>;
  };

  return <div className="article">
    <div className="article__header">
      <div className="article__title">
        <h2 className="article__heading">{card.title}</h2>
        {
          onClose
          && <button className="article__close-button" onClick={handleClose}>X</button>
        }
      </div>
      <div className="article__props">
        <div className="article__information">
          {!hideContentHeader && renderArticleInformation()}
          {renderTextToSpeech()}
        </div>
        <div className="article__actions">
          {!hideContentHeader && renderActionsSection()}
        </div>
      </div>
    </div>
    {
      ((!isShowJournal && !isShowInterview) || isShowJournalArticle)
      && <div className="article__body">
        <p className="article__description">
          {card.description}
        </p>
        {card.pictureUrl && <div className="article__image-wrapper">
          {
            <img src={card.pictureUrl} alt="" className="article__image"/>
          }
        </div>}
        {
          card.text
          && <div
            className="article__content"
            dangerouslySetInnerHTML={{
              __html: card.text
            }}
          />
        }
      </div>
    }
    {renderMeditation()}
    {renderJournal()}
    {renderSelfInterview()}
  </div>;
};
