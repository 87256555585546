import {IDialogueCard, IParticipant} from '../../redux/dialogue/interfaces';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLoadFile} from '../../utils/hooks/useLoadFile';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from '../../redux/interface';
import {
  contentAsyncActions,
  getArticle
} from '../../redux/pageAsyncActions';
import DialogueParticipants from '../dialogue-participants';
import {BotType, IBotAction} from '../../utils/hooks/use-chat-bot/interfaces';
import BotIcon from '../../assets/image/user-settings/BotDefaultAvatar.png';
import UserIcon from '../../assets/image/user-settings/gabiUserDefaulfAvatar.svg';
import {closeArticle} from '../../redux/content/actions';
import {loadArticleAction} from '../../redux/content/contentAsyncActions';
import {BotActionType, BotChat, BotMode, BotNameMode, MessageType} from '@wholesalechange/chatcomponent';
import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';
import {useDictionary} from '../../utils/hooks/useDictionary';
import {useChatBotNew} from '../../utils/hooks/useChatBotNew';
import './styles.scss';
import {BotContent} from '../bot-chat/BotContent';
import {useVideoWebSocket} from '../../utils/hooks/useVideoWebSocket';
import {useVideoDialogue} from '../../utils/hooks/useVideoDialogue';
import {useUploadImage} from '../../utils/hooks/useUploadImage';
import Loader from '../loader';

interface IDialogueChat {
  dialogue?: IDialogueCard | null,
  participant?: IParticipant | null,
  isShowParticipants?: boolean | undefined,
  onHideParticipants?: () => void;
  onAddBot?: () => void;
}

export const DialogueChat: FunctionComponent<IDialogueChat> = ({dialogue, participant, isShowParticipants, onHideParticipants, onAddBot}: IDialogueChat) => {
  const intl = useIntl();
  // ----------
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [isVideoStarted, setIsVideoStarted] = useState(false);
  const [isHost, setIsHost] = useState<boolean | null>(null);
  const [avatar, setAvatar] = useState<string>(UserIcon);
  const [needReloadHistory, setNeedReloadHistory] = useState(false);
  const [isShowContent, setIsShowContent] = useState(true);
  const {
    auth: {userProfile},
    content: {article},
    dialogue: {participants}
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const {getFileUrl, getFileUrlAsync, removeFileAsync} = useLoadFile();
  const {uploadImageUrlAsync} = useUploadImage();

  const dictionary = useDictionary();
  const botMode = BotMode.FullHistory;
  const dialogueChat = useChatBotNew(BotType.Dialogue, botMode);

  const videoDialogue = useVideoDialogue();
  const videoWs = useVideoWebSocket();

  useEffect(() => {
    const avatarId = dialogue?.avatar || userProfile?.dialogAvatarId;

    if (avatarId) {
      getFileUrl(avatarId, (url: string | null) => {
        if (url) {
          setAvatar(url);
        }
      });
    } else {
      setAvatar(BotIcon);
    }
    if (dialogue) {
      dialogueChat.dialogueMethods.getDialogById(dialogue.id);
    }
  }, [dialogue]);

  async function refreshDialogueHistory() {
    if (dialogue) {
      await dialogueChat.dialogueMethods.refreshDialogueHistory();
    }
  }

  useEffect(() => {
    if (needReloadHistory) {
      setNeedReloadHistory(false);

      refreshDialogueHistory();
    }
  }, [participants]);

  useEffect(() => {
    dispatch(closeArticle());
  }, []);

  useEffect(() => {
    if (participant) {
      setIsHost(participant.participantRole === 'ADMIN');
    }
  }, [participant]);

  const pushUserMessage = (text: string) => {
    if (dialogue && participant) {
      dialogueChat.sendMessage(MessageType.Text, participant, {text});
    }
  };

  function handleHelp() {
    pushUserMessage('Help');
  }

  function handleVideoCall() {
    if (dialogue && participant) {
      setIsShowVideo(true);

      if (onHideParticipants) {
        onHideParticipants();
      }
      dispatch(closeArticle());
      setIsShowContent(false);
    }
  }

  function handleAddBot() {
    setNeedReloadHistory(true);
  }

  function handleLeaveVideo() {
    setIsShowVideo(false);
    setIsVideoStarted(false);
  }

  function handleStartVideo() {
    setIsVideoStarted(true);
  }

  function handleSendOverlay(overlayAnswer: IBotAction) {
    if (!dialogue || !participant) {
      return;
    }
    const actionButton = overlayAnswer;

    dialogueChat.sendMessage(MessageType.Action, participant, {
      button: {
        actionType: actionButton.actionType,
        actionBody: actionButton.actionBody,
        actionUrl: actionButton.actionUrl,
        serialNumber: actionButton.serialNumber,
        text: actionButton.text
      }
    });
  }

  const handleSendMessage = (message: string) => {
    pushUserMessage(message.trim());
  };

  async function loadMore() {
    if (dialogue && !dialogueChat.isLoading) {
      await dialogueChat.dialogueMethods.fetchMore();
    }
  }

  function handleShowArticle(code: string) {
    dispatch(contentAsyncActions[getArticle](code));
    setIsShowContent(true);
  }

  function handleSendAction(actionButton: IBotAction) {
    if (!dialogue || !participant) {
      return;
    }
    const actionBody = JSON.parse(actionButton.actionBody);

    switch (actionButton.actionType) {
      case BotActionType.ContentView:
        dispatch(loadArticleAction(actionBody.addActionBody.contentInfo.id));
        setIsShowContent(true);
        dialogueChat.sendMessage(MessageType.Action, participant, {
          button: {
            actionType: actionButton.actionType,
            actionBody: actionButton.actionBody,
            actionUrl: actionButton.actionUrl,
            serialNumber: actionButton.serialNumber,
            text: actionButton.text
          }
        });
        break;
      case BotActionType.OpenURL:
      case BotActionType.ReturnValue:
      case BotActionType.Schedule:
      case BotActionType.Image:
      case BotActionType.Overlay:
        dialogueChat.sendMessage(MessageType.Action, participant, {
          button: {
            actionType: actionButton.actionType,
            actionBody: actionButton.actionBody,
            actionUrl: actionButton.actionUrl,
            serialNumber: actionButton.serialNumber,
            text: actionButton.text
          }
        });
        break;
      default:
        break;
    }
  }

  function handleShowExternalOverlay() {
    if (article) {
      dispatch(closeArticle());
    }
    setIsShowContent(false);
  }

  function renderVideoCallButton() {
    if (isHost) {
      return <GritxButton key='startAVideoCall'
        title={intl.formatMessage({
          id: 'gritx.chat.start.videocall',
          defaultMessage: 'Start a video call'
        })}
        disabled={isVideoStarted}
        variant={ButtonVariant.Outline}
        className="dialogue-chat__video-btn"
        onClick={handleVideoCall}
      />;
    }

    return <GritxButton key='joinAVideoCall'
      title={intl.formatMessage({
        id: 'gritx.chat.join.videocall',
        defaultMessage: 'Join a video call'
      })}
      variant={ButtonVariant.Outline}
      disabled={isVideoStarted}
      className="dialogue-chat__video-btn"
      onClick={handleVideoCall}
    />;
  }

  // function renderDialogueVideo() {
  //   return dialogue && isShowVideo && isHost !== null && participant && videoDialogue && videoWs ? <DialogueVideo dialogueId={dialogue.id}
  //     participant={{
  //       userId: participant.userId,
  //       participantId: participant.participantId,
  //       userName: participant.participantName,
  //       isHost: isHost
  //     }}
  //     azure={{
  //       subscriptionKey: process.env.REACT_APP_AZURE_SUBSCRIPTION_KEY || '',
  //       region: process.env.REACT_APP_AZURE_REGION || ''
  //     }}
  //     agoraAppId={process.env.REACT_APP_AGORA_APP_ID || ''}
  //     callbacks={{
  //       onLeaveVideoCall: handleLeaveVideo
  //     }}
  //     videoApiMethods={{
  //       startVideoCall: videoDialogue.createVideoCall,
  //       getVideoCallActive: videoDialogue.getVideoCallActive,
  //       finishVideoCall: videoDialogue.finishVideoCall,
  //       getAgoraToken: videoDialogue.getVideoCallToken,
  //       getRtmToken: videoDialogue.getVideoCallRtmToken,
  //       setDialogueLanguage: videoDialogue.setDialogueLanguage,
  //       isLoading: videoDialogue.isLoading
  //     }}
  //     videoWsMethods={{
  //       sendMessage: videoWs.sendMessage,
  //       connect: videoWs.connect,
  //       disconnect: videoWs.disconnect,
  //       connected: videoWs.connected,
  //       changeVideoCallSettings: videoWs.changeVideoCallSettings,
  //       isLoading: videoWs.loading
  //     }}
  //     loader={<Loader className='video_loader'/>}/>
  //     : <></>;
  // }

  function handleCloseArticle() {
    dispatch(closeArticle());
    setIsShowContent(false);
  }

  function getBotContent() {
    return (isShowContent || isShowParticipants || isShowVideo) ? <>
      <DialogueParticipants dialogue={dialogue}
        isShowParticipants={isShowParticipants || false}
        onAddBot={handleAddBot}/>
      {/* {renderDialogueVideo()}*/}
      {isShowContent && <BotContent botAnswer={dialogueChat.botAnswer}/>}
    </> : null;
  }

  function renderButtons() {
    return <GritxButton key='help'
      title={intl.formatMessage({
        id: 'gritx.chat.helpButton',
        defaultMessage: 'Help'
      })}
      variant={ButtonVariant.Outline}
      className="dialogue-chat__help-btn"
      onClick={handleHelp}
    />;
  }

  const ttsSettings = {
    azureSubscriptionKey: process.env.REACT_APP_AZURE_SUBSCRIPTION_KEY || '',
    azureRegion: process.env.REACT_APP_AZURE_REGION || ''
  };

  return userProfile && <BotChat isLoading={dialogueChat.isLoading}
    isTyping={dialogueChat.isTyping}
    isSending={dialogueChat.isSending}
    chatAvatar={avatar}
    toolbarButtons={isHost !== null ? [renderVideoCallButton(), renderButtons()] : [renderButtons()]}
    messages={dialogueChat.messages || []}
    onSend={handleSendMessage}
    onSendAction={handleSendAction}
    currentUser={userProfile}
    onLoadMore={loadMore}
    onShowArticle={handleShowArticle}
    onCloseArticle={handleCloseArticle}
    onShowExternalOverlay={handleShowExternalOverlay}
    dictionary={dictionary}
    botMode={botMode}
    isConnected={dialogueChat.isConnected}
    botNameMode={BotNameMode.BOT}
    getFileUrl={getFileUrlAsync}
    removeFile={removeFileAsync}
    uploadImage={uploadImageUrlAsync}
    ttsSettings={ttsSettings}
    showContentInRightPanel={dialogueChat.showContentInRightPanel}
    contentElement={getBotContent()}
  />;
};
